import { NetworkStatus, gql, useQuery } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import QueryResult from "../../components/QueryResult";
import { Button } from "../../components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  ElementsQuery,
  ElementsQueryVariables,
} from "../../graph/compiled/types";

export const ELEMENTS_LIST = gql`
  query Elements($first: Int!, $after: String) {
    elements(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          alternativeNames
        }
      }
    }
  }
`;

const Elements: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading, error, networkStatus, fetchMore } = useQuery<
    ElementsQuery,
    ElementsQueryVariables
  >(ELEMENTS_LIST, { variables: { first: 20 }, fetchPolicy: "no-cache" });
  const fetchingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <>
      <div className="flex justify-end">
        <Button variant="secondary" onClick={() => navigate("/elements/new")}>
          Create
        </Button>
      </div>
      <QueryResult data={data} error={error} loading={loading}></QueryResult>
      <Table>
        <TableCaption>
          {data?.elements.pageInfo.hasNextPage === true && (
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-5 2xl:col-span-6 flex justify-center border-t border-gray-100 pb-10">
              <Button
                size="sm"
                color="gray"
                className="m-4"
                disabled={fetchingMore}
                onClick={() => {
                  fetchMore({
                    query: ELEMENTS_LIST,
                    variables: {
                      after: data?.elements.pageInfo.endCursor,
                      first: 20,
                    },
                  });
                }}
              >
                {fetchingMore ? "Loading more..." : "Load more..."}
              </Button>
            </div>
          )}
          .
        </TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Alternative names</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data &&
            data.elements &&
            data.elements.edges.map((edge) => (
              <TableRow>
                <TableCell className="font-medium">{edge.node.name}</TableCell>
                <TableCell>
                  {edge.node.alternativeNames &&
                    edge.node.alternativeNames.join(", ")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default Elements;
