import { gql, useLazyQuery, useMutation } from "@apollo/client";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ObjectSchema, object, string } from "yup";
import QueryResult from "../../components/QueryResult";
import { Button } from "../../components/ui/button";
import { DateTimePicker } from "../../components/ui/datetimepicker";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  IntakesQuery,
  IntakesQueryVariables,
  NewIntakeMutation,
  NewIntakeMutationVariables,
} from "../../graph/compiled/types";

const INTAKES_LIST = gql`
  query Intakes(
    $first: Int!
    $after: String
    $startAt: String!
    $endAt: String!
  ) {
    supplements(first: $first) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          color
          createdAt
        }
      }
    }
    intakes(first: $first, after: $after, startAt: $startAt, endAt: $endAt) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          description
          consumedAt
          supplement {
            id
            name
            color
          }
        }
      }
    }
  }
`;

const NEW_INTAKE = gql`
  mutation NewIntake(
    $description: String!
    $supplementId: ID!
    $consumedAt: String!
  ) {
    createIntake(
      description: $description
      supplementId: $supplementId
      consumedAt: $consumedAt
    ) {
      errors {
        field
        message
      }
      intake {
        id
        description
        consumedAt
      }
    }
  }
`;

export interface NewIntakeProps {
  description: string;
  supplementId: string;
  consumedAt: string;
}

const formSchema: ObjectSchema<NewIntakeProps> = object({
  description: string().required(),
  supplementId: string().required(),
  consumedAt: string().required(),
});

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [dateRange, setDateRange] = React.useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);
  const [getData, { loading, error, data }] = useLazyQuery<
    IntakesQuery,
    IntakesQueryVariables
  >(INTAKES_LIST, {
    variables: {
      first: 20,
      startAt: dateRange[0].toString(),
      endAt: dateRange[1].toString(),
    },
    fetchPolicy: "no-cache",
  });

  const [createIntake, { data: mData, loading: mLoading, error: mError }] =
    useMutation<NewIntakeMutation, NewIntakeMutationVariables>(NEW_INTAKE);

  const form = useForm<NewIntakeProps>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      description: "",
      supplementId: "",
      consumedAt: "",
    },
  });

  const onSubmit = async (createIntakeProps: NewIntakeProps): Promise<void> => {
    createIntake({
      variables: {
        description: createIntakeProps.description,
        supplementId: createIntakeProps.supplementId,
        consumedAt: createIntakeProps.consumedAt,
      },
    }).then((response) => {
      if (
        response.data?.createIntake?.intake === null ||
        mError !== undefined
      ) {
        response.data?.createIntake?.errors.forEach((error) => {
          form.setError(error.field, {
            type: "server",
            message: error.message,
          });
        });
      } else {
        navigate(0);
      }
    });
  };

  useEffect(() => {
    getData({
      variables: {
        first: 20,
        startAt: dateRange[0].toString(),
        endAt: dateRange[1].toString(),
      },
    });
  }, [dateRange]);

  console.log(data);

  return (
    <>
      <QueryResult data={data} error={error} loading={loading} />

      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>New intake</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 mb-10">
              <div className="flex flex-col space-y-4">
                <FormItem className="my-4 flex flex-col">
                  <FormLabel className="mb-2">Picker</FormLabel>
                  <FormControl>
                    <DateTimePicker
                      date={new Date(form.getValues("consumedAt"))}
                      setDate={(date) =>
                        form.setValue("consumedAt", date.toString())
                      }
                    />
                  </FormControl>
                </FormItem>
              </div>
              <FormField
                control={form.control}
                name="supplementId"
                render={({ field }) => (
                  <FormItem className="w-100">
                    <FormLabel>Supplement</FormLabel>
                    <Select
                      onValueChange={(item) =>
                        form.setValue("supplementId", item)
                      }
                    >
                      <FormControl>
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select a supplement" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {data &&
                          data.supplements.edges
                            .map((edge) => edge.node)
                            .map((node) => (
                              <SelectItem value={node.id} key={node.id}>
                                {node.name}
                              </SelectItem>
                            ))}
                      </SelectContent>
                    </Select>
                    <FormDescription>Add supplement.</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem className="my-4">
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormDescription>
                      Add some description like 2 pills of something.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" className="mt-4">
                Create
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay", // user can switch between the two
        }}
        datesSet={(date) => {
          if (
            date.start.toString() !== dateRange[0].toString() &&
            date.end.toString() !== dateRange[1].toString()
          ) {
            console.log(date);
            setDateRange([date.start, date.end]);
          }
        }}
        dateClick={(info) => {
          info.date.setHours(DateTime.local().hour);
          info.date.setMinutes(DateTime.local().minute);
          form.setValue("consumedAt", info.date.toString());
          setShowDialog(true);
        }}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }}
        events={data?.intakes.edges
          .map((edge) => edge.node)
          .map((node) => {
            // hack so that end date is one second after start date
            let d = new Date(node.consumedAt);
            d.setSeconds(d.getSeconds() + 1);

            return {
              title: `${node.supplement.name} - ${node.description}`,
              start: node.consumedAt,
              end: d,
              color: node.supplement.color!!,
            };
          })}
      />
    </>
  );
};

export default Home;
