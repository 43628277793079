import { createContext } from "react";

interface UserType {
  id: string;
  email: string;
}

const UserContext = createContext<UserType | undefined>(undefined);

export default UserContext;
