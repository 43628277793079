import React, { useContext } from "react";
import { FiLogOut, FiSettings, FiUser } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import UserContext from "../hooks/userContextProvider";
import { cn } from "../lib/utils";
import { Avatar, AvatarFallback } from "./ui/avatar";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const location = useLocation();
  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      <Link
        to="/home"
        className={cn(
          "text-sm font-medium text-muted-foreground  transition-colors hover:text-primary",
          location.pathname.includes("home")
            ? "text-primary"
            : "text-muted-foreground"
        )}
      >
        Home
      </Link>
      <Link
        to="/supplements"
        className={cn(
          "text-sm font-medium text-muted-foreground transition-colors hover:text-primary",
          location.pathname.includes("supplements")
            ? "text-primary"
            : "text-muted-foreground"
        )}
      >
        Supplements
      </Link>
      <Link
        to="/elements"
        className={cn(
          "text-sm font-medium transition-colors hover:text-primary",
          location.pathname.includes("intakes")
            ? "text-primary"
            : "text-muted-foreground"
        )}
      >
        Elements
      </Link>
    </nav>
  );
}

export function UserNav() {
  const user = useContext(UserContext);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            {/* <AvatarImage src={user?.nam} alt="@shadcn" /> */}
            <AvatarFallback>TG</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{user?.email}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <Link to="/users/me">
            <DropdownMenuItem>
              <FiUser className="mr-2 h-4 w-4" />
              <span>Profile</span>
              <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuItem>
            <FiSettings className="mr-2 h-4 w-4" />
            <span>Settings</span>
            <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            window.location.href = "/users/sign_out";
          }}
        >
          <FiLogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
          <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

const TopNavigation: React.FC = () => {
  return (
    <div className="flex-col md:flex mb-4">
      <div className="border-b">
        <div className="flex h-16 items-center px-4">
          <MainNav />
          <div className="ml-auto flex items-center space-x-4">
            <UserNav />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavigation;
