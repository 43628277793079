import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { useMemo } from "react";

export const httpLink = createHttpLink({
  uri: "/graph/api",
});

export const authLink = setContext((_, { headers }) => {
  const token: HTMLMetaElement | null = document.querySelector(
    'meta[name="access_token"]'
  );

  return {
    headers: {
      ...headers,
      "X-CSRF-Token": (
        document.querySelector("meta[name='csrf-token']") as HTMLMetaElement
      ).content,
      // authorization: token instanceof HTMLMetaElement ? `Bearer ${token.content}` : '',
    },
  };
});

export const useClient = (): ApolloClient<NormalizedCacheObject> => {
  const client = useMemo(
    () =>
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({
          typePolicies: {
            Query: {
              fields: {
                supplements: relayStylePagination(),
              },
            },
          },
        }),
      }),
    []
  );

  return client;
};
