import { NetworkStatus, gql, useQuery } from "@apollo/client";
import { format, parseISO } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";
import QueryResult from "../../components/QueryResult";
import { Button } from "../../components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  SupplementsQuery,
  SupplementsQueryVariables,
} from "../../graph/compiled/types";

export const SUPPLEMENTS_LIST = gql`
  query Supplements($first: Int!, $after: String) {
    supplements(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          color
          createdAt
        }
      }
    }
  }
`;

const Supplements: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading, error, networkStatus, fetchMore } = useQuery<
    SupplementsQuery,
    SupplementsQueryVariables
  >(SUPPLEMENTS_LIST, { variables: { first: 20 }, fetchPolicy: "no-cache" });
  const fetchingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <>
      <div className="flex justify-end">
        <Button
          variant="secondary"
          onClick={() => navigate("/supplements/new")}
        >
          Create
        </Button>
      </div>
      <QueryResult data={data} error={error} loading={loading}></QueryResult>
      <Table>
        <TableCaption>
          {data?.supplements.pageInfo.hasNextPage === true && (
            <div className="sm:col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-5 2xl:col-span-6 flex justify-center border-t border-gray-100 pb-10">
              <Button
                size="sm"
                color="gray"
                className="m-4"
                disabled={fetchingMore}
                onClick={() => {
                  fetchMore({
                    query: SUPPLEMENTS_LIST,
                    variables: {
                      after: data?.supplements.pageInfo.endCursor,
                      first: 20,
                    },
                  });
                }}
              >
                {fetchingMore ? "Loading more..." : "Load more..."}
              </Button>
            </div>
          )}
          .
        </TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Color</TableHead>
            <TableHead>Created At</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data &&
            data.supplements &&
            data.supplements.edges.map((edge) => (
              <TableRow>
                <TableCell className="font-medium">{edge.node.name}</TableCell>
                <TableCell>
                  <div
                    style={{ backgroundColor: edge.node.color }}
                    className={`w-4 h-4 rounded-full`}
                  ></div>
                </TableCell>
                <TableCell>
                  {format(parseISO(edge.node.createdAt), "HH:mm, dd MMM yyyy")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default Supplements;
