import { gql, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import ColorPicker from "react-pick-color";
import { useNavigate } from "react-router-dom";
import { ObjectSchema, object, string } from "yup";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import {
  NewSupplementMutation,
  NewSupplementMutationVariables,
} from "../../graph/compiled/types";

const NEW_SUPPLEMENT = gql`
  mutation NewSupplement($name: String!, $color: String!) {
    createSupplement(name: $name, color: $color) {
      errors {
        field
        message
      }
      supplement {
        id
        name
        color
      }
    }
  }
`;

const formSchema: ObjectSchema<{ name: string }> = object({
  name: string().required(),
  color: string().required(),
});

const NewSupplement: React.FC = () => {
  const navigate = useNavigate();
  const [createSupplement, { data: mData, loading: mLoading, error: mError }] =
    useMutation<NewSupplementMutation, NewSupplementMutationVariables>(
      NEW_SUPPLEMENT
    );

  const form = useForm<{ name: string; color: string }>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: "",
      color: "",
    },
  });

  const onSubmit = async (createActivityInput: {
    name: string;
    color: string;
  }): Promise<void> => {
    createSupplement({
      variables: {
        ...createActivityInput,
      },
    }).then((response) => {
      if (
        response.data?.createSupplement?.supplement === null ||
        mError !== undefined
      ) {
        response.data?.createSupplement?.errors.forEach((error) => {
          form.setError(error.field, {
            type: "server",
            message: error.message,
          });
        });
      } else {
        navigate("/supplements");
      }
    });
  };

  return (
    <div className="bg-white px-5 py-5">
      <h1 className="text-2xl font-bold">New Supplement</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 mb-10">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormDescription>The name of the supplement.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormItem className="my-4">
            <FormLabel>Color</FormLabel>
            <FormControl>
              <ColorPicker
                color={"#000000"}
                onChange={(color) => form.setValue("color", color.hex)}
                hideAlpha={true}
              />
            </FormControl>
            <FormDescription>The color of the supplement.</FormDescription>
          </FormItem>
          <Button type="submit" className="mt-4">
            Create
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default NewSupplement;
