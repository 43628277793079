import { ApolloError } from "@apollo/client";
import React from "react";
import { Alert } from "./ui/alert";

interface QueryResultType<TData = any> {
  loading: boolean;
  error?: ApolloError;
  data: TData | undefined;
  children?: any;
}

const QueryResult: React.FC<QueryResultType> = ({
  loading,
  error,
  data,
  children,
}) => {
  if (error != null) {
    return (
      <Alert color="failure">
        <span>
          <span className="font-medium">Something went wrong!</span>{" "}
          {error.message}
        </span>
      </Alert>
    );
  }

  if (loading) {
    return (
      <div className="min-h-[15rem] flex flex-col bg-white dark:shadow-slate-700/[.7]">
        <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
          <div className="flex justify-center">
            <div
              className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (data === undefined) {
    return <p>Nothing to show...</p>;
  }

  return children;
};

export default QueryResult;
