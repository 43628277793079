import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { GetCurrentUserQuery } from "../graph/compiled/types";
import UserContext from "../hooks/userContextProvider";
import Elements from "../pages/elements";
import NewElement from "../pages/elements/new";
import Home from "../pages/home";
import Supplements from "../pages/supplements";
import NewSupplement from "../pages/supplements/new";
import QueryResult from "./QueryResult";
import TopNavigation from "./TopNavigation";

const ProtectedRoute = ({ isAllowed, redirectPath = "/", children = null }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

const CURRENT_USER = gql`
  query GetCurrentUser {
    user {
      id
      email
    }
  }
`;

const App: React.FC = () => {
  const { loading, error, data } = useQuery<GetCurrentUserQuery>(CURRENT_USER);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <div className="mx-auto max-w-4xl mb-4">
      <QueryResult loading={loading} error={error} data={data}>
        <UserContext.Provider value={data?.user}>
          <TopNavigation />
          {/* <TopNavigation />
          <Toaster richColors />
          <CommandDialog open={open} onOpenChange={setOpen}>
            <CommandInput placeholder="Type a command or search..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup heading="Suggestions">
                <CommandItem
                  onSelect={() => {
                    setOpen(false);
                    navigate("/activities/new");
                  }}
                >
                  <BsActivity className="mr-2 h-4 w-4" />
                  <span>Add Activity</span>
                </CommandItem>
              </CommandGroup>
              <CommandSeparator />
              <CommandGroup heading="Settings">
                <CommandItem
                  onSelect={() => {
                    setOpen(false);
                    navigate("/activities");
                  }}
                >
                  <RxActivityLog className="mr-2 h-4 w-4" />
                  <span>Activities</span>
                </CommandItem>
                <CommandItem
                  onSelect={() => {
                    setOpen(false);
                    navigate("/challenges");
                  }}
                >
                  <FiGrid className="mr-2 h-4 w-4" />
                  <span>Challenges</span>
                </CommandItem>
                <CommandItem
                  onSelect={() => {
                    setOpen(false);
                    navigate("/users/me");
                  }}
                >
                  <AiOutlineUser className="mr-2 h-4 w-4" />
                  <span>Profile</span>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </CommandDialog> */}
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            {/* <Route path="/users">
                <Route index={false} path="/users/me" element={<MyProfile />} />
              </Route> */}
            {/* <Route path="/admin">
                <Route element={<ProtectedRoute isAllowed={data?.user?.permissions?.canAccessAdminPanel} />}>
                  <Route index={true} element={<AdminIndex />} />
                  <Route index={false} path="/admin/categories" element={<FacilityCategories />} />
                </Route>
              </Route> */}
            <Route path="/home" element={<Home />} index={true} />
            <Route path="/supplements">
              <Route index={true} element={<Supplements />} />
              <Route
                index={false}
                path="/supplements/new"
                element={<NewSupplement />}
              />
            </Route>
            <Route path="/elements">
              <Route index={true} element={<Elements />} />
              <Route
                index={false}
                path="/elements/new"
                element={<NewElement />}
              />
            </Route>
          </Routes>
        </UserContext.Provider>
      </QueryResult>
    </div>
  );
};

export default App;
