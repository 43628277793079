import { gql, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ObjectSchema, array, object, string } from "yup";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  NewElementMutation,
  NewElementMutationVariables,
} from "../../graph/compiled/types";

export interface NewElementProps {
  name: string;
  alternativeNames: string[];
}

const NEW_ELEMENT = gql`
  mutation NewElement($name: String!, $alternativeNames: [String!]) {
    createElement(name: $name, alternativeNames: $alternativeNames) {
      errors {
        field
        message
      }
      element {
        id
        name
        alternativeNames
      }
    }
  }
`;

const formSchema: ObjectSchema<NewElementProps> = object({
  name: string().required(),
  alternativeNames: array().required(),
});

const NewElement: React.FC = () => {
  const navigate = useNavigate();
  const [createElement, { data: mData, loading: mLoading, error: mError }] =
    useMutation<NewElementMutation, NewElementMutationVariables>(NEW_ELEMENT);

  const form = useForm<NewElementProps>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: "",
      alternativeNames: ["alternative name"],
    },
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: form.control, // control props comes from useForm (optional: if you are using FormContext)
      name: "alternativeNames", // unique name for your Field Array
    }
  );

  console.log(form.getFieldState("alternativeNames"));

  const onSubmit = async (
    createActivityInput: NewElementProps
  ): Promise<void> => {
    createElement({
      variables: {
        ...createActivityInput,
      },
    }).then((response) => {
      if (
        response.data?.createElement?.element === null ||
        mError !== undefined
      ) {
        response.data?.createElement?.errors.forEach((error) => {
          form.setError(error.field, {
            type: "server",
            message: error.message,
          });
        });
      } else {
        navigate("/elements");
      }
    });
  };

  return (
    <div className="bg-white px-5 py-5">
      <h1 className="text-2xl font-bold">New Element</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 mb-10">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormDescription>The name of the supplement.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="mt-4">
            <Label>Alternative Names</Label>
          </div>
          <ul>
            {fields.map((item, index) => (
              <li key={item.id} className="flex space-x-2 mt-2">
                <Input {...form.register(`alternativeNames.${index}`)} />

                <Button
                  type="button"
                  variant="destructive"
                  onClick={() => remove(index)}
                >
                  Delete
                </Button>
              </li>
            ))}
          </ul>

          <Button type="submit" className="mt-4">
            Create
          </Button>

          <Button type="button" variant="outline" onClick={() => append("")}>
            append
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default NewElement;
