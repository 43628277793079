import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "../components/App";
import { useClient } from "../hooks/useClient";
import "./application.css";

function TodorDev(): JSX.Element {
  const apolloClient = useClient();

  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  );
}

document.addEventListener("DOMContentLoaded", () => {
  const root = document.getElementById("root");

  if (root !== null) {
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <TodorDev />
    );
  }
});
